<template>
  <div class="app-container">
    <div style="margin:5px 0px;">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="单位:">
          <el-select v-model="search.institutionName" filterable size="small" clearable placeholder="请选择单位"
            class="form-line-item"
                     ref="selectInstitutionName"
                     @visible-change="isShowSelectOptions"
          >
            <el-option v-for="(item,index) in institutionList" :key='index' :label="item.institutionName"
              :value="item.institutionName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="账号:">
          <el-input placeholder="请输入账号" v-model="search.account" size='small' class="form-line-item">
          </el-input>
        </el-form-item>
        <el-form-item label="用户名:">
          <el-input placeholder="请输入" v-model="search.username" size='small' class="form-line-item">
          </el-input>
        </el-form-item>
        <el-form-item style="margin-left:10px;">
          <el-button type="primary" size="mini" @click="batchSearch" icon="el-icon-search">
            搜索
          </el-button>
          <el-button type="success" size="mini" @click="resetHandle" icon="el-icon-refresh">
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <!-- 表格 -->
    <div>
      <!-- <vxe-toolbar >
        <template #buttons>
          <el-button type="success" size="small" @click="insertHandle()" icon="el-icon-plus">添加账号</el-button>
        </template>
      </vxe-toolbar> -->
      <!-- :loading="loading" -->
      <vxe-table :header-cell-style="headerCellStyle" round align="left" ref="schoolTable" highlight-current-row
        highlight-hover-row :keyboard-config="{isArrow: true}" keep-source class='mytable-scrollbar schoolList'
        :checkbox-config="{trigger: 'cell', highlight: true, range: false}" :loading='loading' :data="paperList">
        <vxe-column type='seq' title="序号" width="100" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="username" title="账号" width="300" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="nickname" title="用户名" width="300" :show-overflow="'tooltip'"></vxe-column>
        <vxe-column field="institutionName" title="单位名称" width="300" :show-overflow="'tooltip'"></vxe-column>
        <!-- <vxe-column field="authority" title="权限" width="200" :show-overflow="'tooltip'"></vxe-column> -->
        <vxe-column title="操作" min-width="300">
          <template #default="{ row }">
            <!-- <el-button size="mini" type="text" @click="editHandle(row)">编辑</el-button>
            <el-button size="mini" type="text" @click="removeHandle(row.id)">删除</el-button>
            <el-button size="mini" type="text" @click="enterHandle(row.id)">进入</el-button> -->
            <!-- <el-button size="mini" type="text" @click="resetPwdHandle(row.id)">重置密码</el-button> -->
            <el-popconfirm title="确定删除吗？" @confirm="resetPwdHandle(row.id)">
              <el-button  slot="reference" size="mini" type="warning">
                重置密码
              </el-button>
            </el-popconfirm>
            <el-button size="mini" type="primary" @click="schoolDistributionShow(row)" style="margin-left:5px">批次分配</el-button>
            <el-button size="mini" type="primary" @click="schoolhasDistributionShow(row)" style="margin-left:5px">批次查看</el-button>
            <el-button size="mini" type="primary" @click="entryTo(row)" style="margin-left:5px">进入</el-button>
          </template>
        </vxe-column>
      </vxe-table>
      <vxe-pager perfect align='right' :current-page.sync="page.currentPage" :page-size.sync="page.pageSize"
        :total="page.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: page.total}]"
        :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
        @page-change="handlePageChange">
        <template #left>
          <vxe-button size="small" @click="firstPage">首页
          </vxe-button>
        </template>
      </vxe-pager>

      <!-- 查看 -->
      <vxe-modal v-model="isVisibleShow" :position="{top: 5}" width="800" height="850" show-zoom resize :transfer='true'>
        <template #title>
          院校可查看批次分配
        </template>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="单位:">
            <el-select v-model="searchBatch.institutionId" size="medium" filterable  clearable @change="handleInstitution" placeholder="请选择单位" class="form-line-item"
                       ref="selectInstitution"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in schoolInstitutionList" :key='index' :label="item.institutionName"
                         :value="item.institutionId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="批次:">
            <el-select v-model="searchBatch.batchId" size='medium' clearable placeholder="请选择批次"
                       class="form-line-item" :disabled='schoolBatchShow'
                       ref="selectBatchId"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in schoolBatchList" :key='item.batchId' :label="item.batchName"
                         :value="item.batchId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" size="small" icon="el-icon-search" @click="getBatchDataList">
              搜索
            </el-button>
            <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetBatchHandle">
              重置
            </el-button>
          </el-form-item>
        </el-form>

        <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                   highlight-hover-row align="center" ref="myTable1" :data="batchDataList"
                   class="mytable-scrollbar financialList" show-footer
                   :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                   :loading='batchLoading' height="600">
          <!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
          <vxe-column type='checkbox' title="全选" width="100" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="institutionName" title="单位名称" width="334" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="batchName" title="批次名称" width="334" :show-overflow="'tooltip'"></vxe-column>
        </vxe-table>
        <vxe-pager perfect align='right' :current-page.sync="pageBatch.currentPage" :page-size.sync="pageBatch.pageSize"
                   :total="pageBatch.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: pageBatch.total}]"
                   :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                   @page-change="handlePageBatchChange">
        </vxe-pager>

        <el-button size="medium" type="primary" @click="schoolDistribution" style="margin-top:10px; margin-left: 670px">批次分配</el-button>
      </vxe-modal>

      <vxe-modal v-model="isDeleteVisibleShow" :position="{top: 5}" width="800" height="850" show-zoom resize :transfer='true'>
        <template #title>
          院校批次查看及移除
        </template>
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item label="单位:">
            <el-select v-model="searchhasBatch.institutionId" size="medium" filterable  clearable @change="handleHasInstitution" placeholder="请选择单位" class="form-line-item"
                       ref="selectInstitution"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in schoolhasInstitutionList" :key='index' :label="item.institutionName"
                         :value="item.institutionId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="批次:">
            <el-select v-model="searchhasBatch.batchId" size='medium' clearable placeholder="请选择批次"
                       class="form-line-item" :disabled='schoolhasBatchShow'
                       ref="selectBatchId"
                       @visible-change="isShowSelectOptions"
            >
              <el-option v-for="(item,index) in schoolhasBatchList" :key='item.batchId' :label="item.batchName"
                         :value="item.batchId"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="margin-left:10px;">
            <el-button type="primary" size="small" icon="el-icon-search" @click="getHasBatchDataList">
              搜索
            </el-button>
            <el-button type="primary" size="small" icon="el-icon-refresh" @click="resetBatchHandle">
              重置
            </el-button>
          </el-form-item>
        </el-form>

        <vxe-table :header-cell-style="headerCellStyle" :cell-style='cellStyle' border stripe round
                   highlight-hover-row align="center" ref="myTable2" :data="batchHasDataList"
                   class="mytable-scrollbar financialList" show-footer
                   :checkbox-config="{trigger: 'row', highlight: true, range: true}" :export-config="{}" :print-config="{}"
                   :loading='hasBatchLoading' height="600">
          <!--                <vxe-column type='seq' title="序号" width="6%" :show-overflow="'tooltip'"></vxe-column>-->
          <vxe-column type='checkbox' title="全选" width="100" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="institutionName" title="单位名称" width="334" :show-overflow="'tooltip'"></vxe-column>
          <vxe-column field="batchName" title="批次名称" width="334" :show-overflow="'tooltip'"></vxe-column>
        </vxe-table>
        <vxe-pager perfect align='right' :current-page.sync="pageHasBatch.currentPage" :page-size.sync="pageHasBatch.pageSize"
                   :total="pageHasBatch.total" :page-sizes="[10, 20, 100, 1000, {label: '全量数据', value: pageHasBatch.total}]"
                   :layouts="['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'Total']"
                   @page-change="handlePageBatchChange">
        </vxe-pager>

        <el-button size="medium" type="danger" @click="schoolDelete" style="margin-top:10px; margin-left: 670px">批次移除</el-button>
      </vxe-modal>


      <vxe-modal v-model="showEdit" :title="isEdit ? '编辑' : '新增'" :position="{top: '0px'}" width="800" min-height="300"
        :loading="submitLoading" :show-footer='true' resize destroy-on-close>
        <template #default>
          <vxe-form :data="formData" :rules="formRules" title-align="right" title-width="100" @submit="submitEvent">
            <vxe-form-item field="account" title="账号" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.account" placeholder="请输入账号"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="password" title="密码" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.password" placeholder="请输入账号"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="username" title="用户名" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-input v-model="data.username" placeholder="请输入单位名称"></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item field="institution" title="归属单位" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-select v-model="data.institution" placeholder="请选择" size="medium" transfer>
                  <vxe-option value="南京理工大学" label="南京理工大学"></vxe-option>
                  <vxe-option value="南京大学" label="南京大学"></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
            <vxe-form-item field="authority" title="权限策略" :span="12" :item-render="{}">
              <template #default="{ data }">
                <vxe-select v-model="data.authority" placeholder="请选择" size="medium" transfer>
                  <vxe-option value="权限名称1" label="权限名称1"></vxe-option>
                  <vxe-option value="权限名称2" label="权限名称2"></vxe-option>
                  <vxe-option value="权限名称3" label="权限名称3"></vxe-option>
                </vxe-select>
              </template>
            </vxe-form-item>
          </vxe-form>
        </template>
        <template #footer>
          <el-button @click="cfmInsertSchoolHandle" type="primary" size="small">确认</el-button>
        </template>
      </vxe-modal>
    </div>
  </div>
</template>

<script>
import base from '@/api/http/baseUrl';
import row from "element-ui/packages/row";
  export default {
    name: 'SchoolAccount',
    computed: {
      row() {
        return row
      }
    },
    data() {
      return {
        submitLoading:false,
        loading: false,
        batchLoading: false,
        hasBatchLoading: false,
        schoolBatchShow: true,
        schoolhasBatchShow:true,
        schoolInstitutionList: [],
        schoolBatchList:[],
        batchDataList:[],
        batchHasDataList:[],
        distributionBatchList: [],
        schoolhasInstitutionList: [],
        schoolhasBatchList: [],
        search: {
          institutionName: '',
          account: '',
          username:''
        },
        searchBatch:{
          institutionId: '',
          batchId: ''
        },
        searchhasBatch:{
          institutionId: '',
          batchId: ''
        },
        institutionList: [{
          institutionId: '',
          institutionName: ''
        }],
        //论文列表
        paperList: [{
          id: '',
          nickname: '张三',
          username: '13062597448',
          institutionName: '南京理工大学',
          authority: '权限名称1'
        }],
        formData: {
          username: '张三',
          account: '13062597448',
          institution: '南京理工大学',
          authority: '权限名称1'
        },
        //分页
        page: {
          currentPage: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [1, 5, 10, 15, 20],
          total: 0
        },
        pageBatch: {
          currentPage: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [1, 5, 10, 15, 20],
          total: 0
        },
        pageHasBatch:{
          currentPage: 1,
          pageSize: 10,
          pageCount: 40, //自己用
          pageSizes: [1, 5, 10, 15, 20],
          total: 0
        },
        //弹出框
        showEdit: false,
        isEdit: true,
        isVisibleShow: false,
        isDeleteVisibleShow: false
      }
    },
    created() {
        window.addEventListener('keydown', this.handleKeyPress);
      this.getInstitution();
      this.batchSearch();
    },
    methods: {
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.batchSearch();
            }
        },

        //获取送审单位
      getInstitution() {

        this.$api.schoolCustom.getSendingInstitution()
          .then(res => {
            this.institutionList = res.data.data;
          })
          .catch(err => {
            this.$message.warning('接口错误');
          })
      },
      //获取论文列表
      getPaperList() {
        let search = this.search;
        this.loading = true;
        let param = {
          "nickname": search.username,
          "username": search.account,
          "institutionName": search.institutionName,
          pageNum: this.page.currentPage,
          pageSize: this.page.pageSize,
        }
        this.$api.account.getAllInstitutionAccount(param)
          .then(res => {
            this.paperList = res.data.data;
            this.page.total = res.data.count ? res.data.count : 0;
            this.loading = false;
          })
      },
      //搜索
      batchSearch() {
        this.getPaperList();
      },
      resetHandle() {
        this.search = {
          institutionName: '',
          username:'',
          account: '',
        };
      },
      resetBatchHandle(){
        this.searchBatch = {
          institutionId: '',
          batchId: ''
        };
      },
      //添加账号
      insertHandle() {
        this.showEdit = true;
        this.isEdit = false;
      },
      getBatchDataList() {
        console.log(1)
        this.batchLoading = true;
        // let param = new URLSearchParams();
        // param.append("institutionName", this.search.institutionName);
        // param.append("batchName", this.search.batchName);
        // param.append("isFiled", this.search.isFiled);
        //
        // param.append("pageNum", this.page.currentPage);
        // param.append("pageSize", this.page.pageSize);
        let param ={
          // institutionName:this.search.institutionName,
          // batchName:this.search.batchName,
          institutionId:this.searchBatch.institutionId,
          batchId:this.searchBatch.batchId,
          pageNum:this.pageBatch.currentPage,
          pageSize:this.pageBatch.pageSize,
        }
        console.log(param);
        this.$api.batchManagement.getWorkBatchList(param)
            .then(res => {
              console.log(res.data.data);
              this.batchDataList = res.data.data;
              this.pageBatch.total = res.data.count;
              this.batchLoading = false;
            }).catch(err => {
          this.$message.warning("服务器维护！");
        });
      },
      getHasBatchDataList() {
        console.log(1)
        this.hasBatchLoading = true;
        // let param = new URLSearchParams();
        // param.append("institutionName", this.search.institutionName);
        // param.append("batchName", this.search.batchName);
        // param.append("isFiled", this.search.isFiled);
        //
        // param.append("pageNum", this.page.currentPage);
        // param.append("pageSize", this.page.pageSize);
        let param ={
          // institutionName:this.search.institutionName,
          // batchName:this.search.batchName,
          institutionId:this.searchhasBatch.institutionId,
          batchId:this.searchhasBatch.batchId,
          sysUserId: this.userId,
          pageNum:this.pageHasBatch.currentPage,
          pageSize:this.pageHasBatch.pageSize,
        }
        console.log(param);
        this.$api.batchManagement.getHasBatchDataList(param)
            .then(res => {
              console.log(res.data.data);
              this.batchHasDataList = res.data.data;
              this.pageHasBatch.total = res.data.count;
              this.hasBatchLoading = false;
            }).catch(err => {
          this.$message.warning("服务器维护！");
        });
      },
      schoolDistributionShow(row){
        console.log(row)
        this.userId = row.id
        this.isVisibleShow = true
        this.getSchoolInstitution()
        this.getBatchDataList()
      },
      schoolhasDistributionShow(row){
        console.log(row)
        this.userId = row.id
        this.isDeleteVisibleShow = true
        this.getSchoolHasInstitution()
        this.getHasBatchDataList()
      },
      getSchoolInstitution() {
        // let params = new URLSearchParams();
        // params.append("isFiled", null);
        let params = {
          isFiled: null
        }
        this.$api.schoolCustom.getInstitution(params)
            .then(res => {
              console.log(res);
              this.schoolInstitutionList= res.data.data;
            })
            .catch(err => {
              this.$message.warning('接口错误');
            })
      },
      getSchoolHasInstitution() {
        // let params = new URLSearchParams();
        // params.append("isFiled", null);
        // params.append("institutionAccountId", this.userId);
        let params = {
          // isFiled: null,
          institutionAccountId: this.userId
        }
        console.log(params)
        this.$api.schoolCustom.getSchoolHasInstitution(params)
            .then(res => {
              console.log(res);
              this.schoolhasInstitutionList= res.data.data;
            })
            .catch(err => {
              this.$message.warning('接口错误');
            })
      },
      //获取送审单位的批次
      getBatch(institutionId) {
        // let params = new URLSearchParams();
        // params.append("institutionId", institutionId);
        // params.append("isFiled",null);

        let params = {
          institutionId:institutionId,
          isFiled: null
        }
        console.log(params)
        this.$api.schoolCustom.getSchoolBatch(params)
            .then(res => {
              console.log(res.data.data);
              this.schoolBatchList = res.data.data;
            })
            .catch(err => {
              this.$message.warning("服务器维护");
            });
      },
      //获取送审单位的批次
      getHasBatch(institutionId) {
        // let params = new URLSearchParams();
        // params.append("institutionId", institutionId);
        // params.append("isFiled",null);
        let params = {
          institutionId:institutionId,
          institutionAccountId: this.userId,
          isFiled: null
        }
        console.log(params)
        this.$api.schoolCustom.getHasBatch(params)
            .then(res => {
              console.log(res.data.data);
              this.schoolhasBatchList = res.data.data;
            })
            .catch(err => {
              this.$message.warning("服务器维护");
            });
      },
      schoolDistribution(){
        // 得到所有checkbox
        let ids = [];
        // console.log(this.$refs.myTable.getCheckboxRecords())
        let checkBox = this.$refs.myTable1.getCheckboxRecords();
        for(let i=0;i<checkBox.length;i++){
          ids.push(checkBox[i].batchId);
        }
        this.distributionBatchList = ids;
        console.log(this.distributionBatchList)
        console.log(this.userId)
        if(this.distributionBatchList.length == 0){
          this.$message.warning('请至少选择一个批次');
        }else{
          let params = new URLSearchParams();
          params.append('sysUserId', this.userId);
          this.$api.batchManagement.getSchoolUserCurBatchList(params)
              .then(res => {
                if(res.data.code == 200){
                  let curBatchList = res.data.data;
                  let distributionBatchList = this.distributionBatchList.filter(item => {return curBatchList.indexOf(item) < 0});
                  if(distributionBatchList.length != 0){
                    let params2 = {
                      "sysUserId": this.userId,
                      "distributionBatchList": distributionBatchList
                    }
                    this.$api.batchManagement.insertSchoolBatchDistribution(params2)
                        .then(res => {
                          if(res.data.code == 200){
                            this.$message.success('批次分配成功');
                            this.refresh();
                            this.isVisibleShow = false
                            // this.isBatchDistributionShow = false;
                          }else{
                            this.$message.warning('服务器维护')
                          }
                        })
                  }else{
                    this.$message.warning('当前用户已被分配所选批次，请勿重复分配')
                  }
                }else{
                  this.$message.warning('服务器维护')
                }
              })
        }
      },
      schoolDelete(){
        // 得到所有checkbox
        let ids = [];
        // console.log(this.$refs.myTable.getCheckboxRecords())
        let checkBox = this.$refs.myTable2.getCheckboxRecords();
        for(let i=0;i<checkBox.length;i++){
          ids.push(checkBox[i].batchId);
        }
        this.distributionBatchList = ids;
        console.log(this.distributionBatchList)
        console.log(this.userId)
        if(this.distributionBatchList.length == 0){
          this.$message.warning('请至少选择一个批次');
        }else{
          let params = {
            "sysUserId": this.userId,
            "distributionBatchList": ids
          }
          console.log(params);
          this.$api.batchManagement.deleteBatchSchoolDistribution(params)
              .then(res => {
                if(res.data.code == 200){
                  this.$message.success('移除成功');
                  this.hasBatchLoading = true;
                  this.refresh2();
                  this.hasBatchLoading = false;
                }else{
                  this.$message.warning('服务器维护')
                }
              })
        }
      },
      //机构选择改变
      handleInstitution(val) {
        this.searchBatch.batchId = '';
        if (val == '') {
          this.schoolBatchShow = true;
          return;
        }
        this.schoolBatchShow = false;
        this.getBatch(val);
      },
      handleHasInstitution(val) {
        this.searchhasBatch.batchId = '';
        if (val == '') {
          this.schoolhasBatchShow = true;
          return;
        }
        this.schoolhasBatchShow = false;
        this.getHasBatch(val);
      },
      refresh() {
        this.searchBatch = {
          institutionId: '',
              batchId: ''
        },
        this.getBatchDataList();
      },
      refresh2() {
        this.searchHasBatch = {
          institutionId: '',
          batchId: ''
        },
            this.getHasBatchDataList();
      },
      //编辑账号
      editHandle(row) {
        this.showEdit = true;
        this.isEdit = true;
        this.formData = {
          id: row.id,
          name: row.name,
          account: row.account,
          password: ''
        }
      },
      // 进入院校端
      entryTo(row){        
        window.open(`${base.schoolUrl}#/explanation?token=${row.url}`);
      },
      //提交添加的账号
      confirmInsert() {
        let param = this.formData;
        if (this.isEdit) {
          this.$axios.post('user/update_institution_account', param)
            .then(res => {
              if (res.data.data == 1) {
                this.$message.success("修改成功！");
                this.batchSearch();
                this.showEdit = false;
              } else {
                this.$message.warning("修改失败！");
              }
            }).catch(err => {
              console.log('服务器维护！');
            })
        } else {
          this.$axios.post('user/add_institution_account', param)
            .then(res => {
              if (res.data.data == 1) {
                this.$message.success("添加成功！");
                this.batchSearch();
                this.showEdit = false;
              } else {
                this.$message.warning("添加失败！");
              }
            }).catch(err => {
              console.log('服务器维护！');
            })
        }
      },
      //重置添加表单
      resetInsert() {
        this.formData = {
          name: '',
          account: '',
          password: ''
        }
      },
      //删除账号
      removeHandle(id) {
        let param = {
          id: id
        }
        this.$axios.post('/user/delete_institution_account', param)
          .then(res => {
            if (res.data.data == 1) {
              this.$message.success('删除成功！');
              this.batchSearch();
            } else {
              this.$message.warning('删除失败！');
            }
          }).catch(err => {
            console.log('服务器维护！');
          })
      },

      //保存
      saveHandle(row) {
        const $table = this.$refs.schoolTable
        $table.clearActived().then(() => {
          // this.loading = true
          console.log(row);
        })

      },
      //取消
      cancelHandle(row) {
        const $table = this.$refs.schoolTable
        $table.clearActived().then(() => {
          // 还原行数据
          $table.revertData(row)
        })
      },
      //进入账号
      enterHandle(id) {
        let param = {
          id: id
        }
        this.$axios.post('/user/interior_login', param)
          .then(res => {
            let {
              href
            } = this.$router.resolve({
              name: 'enter',
              query: {
                username: res.data.data.username,
                id: res.data.data.id,
                university: res.data.data.university
              }
            })
            window.open(href, '_blank');
          })
          .catch(err => {
            this.$message.warning('服务器维护！')
          })

      },
      //重置密码
      resetPwdHandle(id) {
        this.$axios.post('/user/reset_password', {
            id: id,
            type: 0
          })
          .then(res => {
            if (res.data.data == 1) {
              this.$message.success('重置成功！');
            } else {
              this.$message.warning('重置失败！');
            }
          }).catch(err => {
            console.log('服务器维护！');
          })
      },
      //分页操作
      handlePageChange({
        currentPage,
        pageSize
      }) {
        this.page.currentPage = currentPage;
        this.page.pageSize = pageSize;
        this.getPaperList();
      },
      handlePageBatchChange({
        currentPage,
        pageSize
      }){
        this.pageBatch.currentPage = currentPage;
        this.pageBatch.pageSize = pageSize;
        this.getBatchDataList();
      },
      firstPage() {
        this.page.currentPage = 1;
        this.getPaperList();
      },
      endPage() {
        this.page.currentPage = parseInt(this.page.total / this.page.pageSize) + (this.page.total % this.page
          .pageSize == 0 ? 0 : 1);
        this.getPaperList();
      },
      headerCellStyle({
        row,
        column,
        rowIndex,
        columnIndex
      }) {
        return "background-color:#e0e0e0f1"
      },
        isShowSelectOptions(isShowSelectOptions){
            if(!isShowSelectOptions) this.$refs.selectInstitutionName.blur();

        },
    },
    components: {

    }
  }
</script>

<style scoped>

</style>

<style>
  .schoolList .vxe-table--body-wrapper {
    height: calc(100vh - 259px);
  }
</style>